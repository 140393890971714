/* QuestionAnswerPage.css */
.question-answer-section {
    margin-bottom: 40px;
  }
  
  .question-answer-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .questions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .question {
    width: 80%;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
  }
  
  .question h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .question p {
    margin-top: 10px;
    font-size: 16px;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-left: 2px solid #333;
    border-bottom: 2px solid #333;
    transition: transform 0.3s ease;
  }
  
  .arrow.down {
    transform: translateY(-50%) rotate(45deg);
  }
  
  .open {
    background-color: #f9f9f9;
  }
  
  .open .arrow {
    transform: translateY(-50%) rotate(225deg);
  }
  