@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: auto;
  padding: auto;
}

.mr-20 {
  margin-right: 20px !important;
}
