/* TechNewsPage.css */
.tech-news-section {
    margin-bottom: 40px;
  }
  
  .tech-news-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .news-item {
    margin-bottom: 20px;
  }
  
  .news-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  