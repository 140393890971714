 /* GuidanceSection.css */
.guidance-section {
    margin-bottom: 40px;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .career-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .career-option {
    width: 30%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .career-option h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .roadmaps-resources {
    margin-top: 20px;
  }
  
  .roadmaps-resources h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .question-answer-section {
    margin-bottom: 40px;
  }
  
  .question-answer-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .question {
    margin-bottom: 20px;
  }
  
  .question h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .tech-news-section {
    margin-bottom: 40px;
  }
  
  .tech-news-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .news-item {
    margin-bottom: 20px;
  }
  
  .news-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  